import React from "react";
import { useFormik } from "formik";
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  CircularProgress,
  Modal,
  Box,
} from "@material-ui/core";
import {
  addUser,
  cities_sa_eng,
  cities_uae_eng,
  getPresignedUrl,
  retailer_sa_eng,
  retailer_uae_eng,
  validationSchema,
} from "../../../../utils";
import axios from "axios";
import saudi_cc_img from "../../../../assets/img/saudi_cc_img.png";
import thankyou_message from "../../../../assets/img/thankyou_message.png";
import { ConfettiAnimation } from "../../../../animations";

interface IProps {
  country: Country;
  setCountry: any;
  page: Page;
  setPage: any;
}
const useStyles: any = makeStyles(theme => ({
  jss__1: {
    "& .MuiInputLabel-formControl": {
      right: "auto",
      left: 0,
    },
    "& .MuiSelect-iconFilled": {
      right: "7px",
      left: "auto",
    },
  },
}));
export const FormContent_English = (props: IProps) => {
  const [submitDisable, setSubmitDisable] = React.useState<boolean>(false);
  const [openLightbox, setOpenLightbox] = React.useState(false);

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      countryCode: "+966",
      phoneNumber: "",
      city: "",
      retailer: "",
      retailer_user: "",
      purchase_receipt: {} as File,
      t_c: false,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      getPresigned_Url(values);
    },
  });
  async function getPresigned_Url(values: any) {
    setSubmitDisable(true);
    try {
      let payload = {
        name: values.fullName,
        email: values.email,
        mobileNumber: `${values.countryCode}${values.phoneNumber}`.toString(),
        city: values.city,
        retailer: values.retailer === "Other" ? values.retailer_user : values.retailer,
        file: values.purchase_receipt,
        country: props.country,
      };
      if (payload.file && typeof payload.file === 'object' && payload.file.size > 0) {
        const signedUrlResp = await getPresignedUrl();
        const { presignedUrl } = signedUrlResp.data.body;
        await axios.put(presignedUrl, payload.file, {
          headers: { "Content-Type": payload.file!.type },
        });
        payload = { ...payload, file: presignedUrl.split("?")[0] }
      }
      else
        payload = { ...payload, file: `` }

      const resp = await addUser(payload);
      if (resp.status === 200) {
        if (resp.data.statusCode === 200) {
          formik.resetForm();
          setOpenLightbox(true);
          return;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitDisable(false);
    }
  }
  const handleLightbox = () => {
    setOpenLightbox(!openLightbox);
    props.setPage("Home");
  };
  return (
    <>
      <div className='jss_23'>
        <form
          onSubmit={e => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <FormControl dir='ltr' fullWidth>
            <TextField
              className={`jss_24 ${classes.jss__1}`}
              required
              fullWidth
              id='fullName'
              name='fullName'
              label='Full Name'
              value={formik.values.fullName}
              variant='filled'
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </FormControl>
          <FormControl dir='ltr' fullWidth>
            <TextField
              className={`jss_24 ${classes.jss__1}`}
              fullWidth
              id='email'
              name='email'
              label='Email Address (optional)'
              value={formik.values.email}
              variant='filled'
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </FormControl>
          <FormControl fullWidth className='jss_28'>
            <Input
              className={`jss_24 jss_38 ${classes.jss__1}`}
              required
              disabled
              id='countryCode'
              name='countryCode'
              style={{

              }}
              startAdornment={
                <InputAdornment position='start'>
                  <img className='jss_27' alt='KSA Flag' src={saudi_cc_img} />
                </InputAdornment>
              }
              value={formik.values.countryCode}
              onChange={formik.handleChange}
              error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
            />

            <TextField
              dir='ltr'
              className={`jss_24 jss_39 ${classes.jss__1}`}
              required
              type={"tel"}
              id='phoneNumber'
              name='phoneNumber'
              label='Mobile Number'
              value={formik.values.phoneNumber}
              variant='filled'
              onChange={formik.handleChange}
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            />
          </FormControl>
          <FormControl dir='ltr' fullWidth>
            <TextField
              className={`jss_24 ${classes.jss__1}`}
              select
              required
              fullWidth
              id='city'
              name='city'
              label='City'
              value={formik.values.city}
              variant='filled'
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            >
              {props.country === "KSA"
                ? cities_sa_eng.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })
                : cities_uae_eng.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </TextField>
          </FormControl>
          <FormControl dir='ltr' fullWidth>
            <TextField
              className={`jss_24 ${classes.jss__1}`}
              select
              required
              fullWidth
              id='retailer'
              name='retailer'
              label='Retailer'
              value={formik.values.retailer}
              variant='filled'
              onChange={formik.handleChange}
              error={formik.touched.retailer && Boolean(formik.errors.retailer)}
              helperText={formik.touched.retailer && formik.errors.retailer}
            >
              {props.country === "KSA"
                ? retailer_sa_eng.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })
                : retailer_uae_eng.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </TextField>
          </FormControl>
          <FormControl dir='ltr' fullWidth>
            <TextField
              className={
                formik.values.retailer === "Other"
                  ? `jss_24 ${classes.jss__1}`
                  : `jss_24 jss_40 ${classes.jss__1}`
              }
              required={formik.values.retailer === "Other"}
              fullWidth
              id='retailer_user'
              name='retailer_user'
              label='Retailer'
              value={formik.values.retailer_user}
              variant='filled'
              onChange={formik.handleChange}
              error={formik.touched.retailer_user && Boolean(formik.errors.retailer_user)}
              helperText={formik.touched.retailer_user && formik.errors.retailer_user}
            />
          </FormControl>
          <FormControl fullWidth>
            <Input
              className={`jss_24 ${classes.jss__1}`}
              required
              disabled
              fullWidth
              id='purchase_receipt'
              name='purchase_receipt'
              endAdornment={
                <InputAdornment className='jss_42' position='end'>
                  <Button component='label' variant='contained' className='jss_25' color='primary'>
                    + Choose File
                    <input
                      hidden
                      id='purchase_receipt'
                      name='purchase_receipt'
                      accept='image/*'
                      type='file'
                      onChange={(event: any) => {
                        formik.setFieldValue("purchase_receipt", event.target.files[0]);
                      }}
                    />
                  </Button>
                </InputAdornment>
              }
              value={
                formik.values.purchase_receipt?.name
                  ? formik.values.purchase_receipt?.name
                  : "Receipt (optional)"
              }
              onChange={formik.handleChange}
              error={formik.touched.purchase_receipt && Boolean(formik.errors.purchase_receipt)}
            />
            <Typography className={`jss_24 jss_30 ${classes.jss__1}`} variant='h4'>
              <span className='jss_31'>*</span>
              Only Images allowed
            </Typography>
          </FormControl>
          <FormControl dir='ltr' fullWidth>
            <FormControlLabel
              className={`jss_24 jss_27${classes.jss__1}`}
              control={
                <Checkbox
                  required
                  disableRipple
                  disableTouchRipple
                  id='t_c'
                  name='t_c'
                  checked={formik.values.t_c}
                  onChange={formik.handleChange}
                />
              }
              label={
                <span className='jss_33'>
                  <Typography variant='h4' className='jss_37'>
                    I agree to all &nbsp;
                  </Typography>
                  <Typography variant='h4'>
                    <a
                      className='jss_36'
                      target={"_blank"}
                      // href='/'
                      href={"/TermsConditions-English.html"}
                    // selectedCountry === 'UAE'
                    // ?
                    // : 'https://www.bakeations.com/PrivacyPolicy_KSA.html'
                    >
                      Terms & Conditions
                    </a>
                  </Typography>
                </span>
              }
            />
          </FormControl>
          <FormControl dir='ltr' fullWidth>
            <Button variant='contained' type='submit' className='jss_32 flag' color='primary'>
              {submitDisable ? <CircularProgress /> : "Submit"}
            </Button>
          </FormControl>
          <FormControl fullWidth dir='ltr'>
            <span className='jss_41'>
              <Typography variant='h4' className='jss_37'>
                For any queries, you may contact us at:
              </Typography>
              <Typography variant='h4' className='jss_36'>
                <a href='mailto:contact@altajandfarmcontest.com'>contact@altajandfarmcontest.com</a>
              </Typography>
            </span>
          </FormControl>
        </form>
      </div>
      <Modal
        open={openLightbox}
        onClose={handleLightbox}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <>
          {openLightbox && <ConfettiAnimation />}
          <Box
            style={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50vw",
              maxWidth: '400px',
              maxHeight: "50vh !important",
              padding: "2%",
              border: "0",
              boxShadow: "24",
              backgroundColor: "white",
              borderRadius: '5px'
            }}
          >
            <div className='jss_47'>
              <img src={thankyou_message} className='jss_48' alt='Thank you Message' />
              <Button
                color='primary'
                className='jss_49 flag'
                variant='contained'
                component='label'
                onClick={handleLightbox}
              >
                OK
              </Button>
            </div>
          </Box>
        </>
      </Modal>
    </>
  );
};
