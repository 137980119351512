import React from "react";
import "./App.css";
import { Footer, LanguageButton } from "./components";
import { Home } from "./pages";

export const App = () => {
  const [page, setPage] = React.useState<Page>("Home");
  const [country, setCountry] = React.useState<Country>("KSA");
  const [language, setLanguage] = React.useState<Language>("Arabic");

  return (
    <div className='App'>
      {/* {page === "Home" ? ( */}
      <Home
        page={page}
        setPage={setPage}
        country={country}
        setCountry={setCountry}
        language={language}
      />
      {/* ) : (
        <SubmitForm
          page={page}
          setPage={setPage}
          country={country}
          setCountry={setCountry}
          language={language}
        />
      )} */}
      <div className='jss_45'>
        <LanguageButton language={language} setLanguage={setLanguage} />
      </div>
      <Footer
        language={language}
      />
    </div>
  );
};

export default App;
