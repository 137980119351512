import React from "react";
import backgroundFooter from "../../assets/img/bg_3_web_arb.png";
import backgroundFooterEng from "../../assets/img/bg_3_web_eng.png";

// import backgroundFooter_mob from "../../assets/img/Background3_Mob.png";

interface IProps {
  language: Language
}
export const Footer = (props: IProps) => {
  // const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);
  // window.addEventListener("resize", (e: any) => setScreenWidth(e.target.innerWidth));

  return (
    <div style={{ display: "flex", width: '100%' }}>
      {/* {screenWidth > 700 ? ( */}
      <img
        src={props.language === "Arabic" ? backgroundFooter : backgroundFooterEng}
        className={"jss_27"}
        alt='Footer'
      />
      {/* ) : (
        <img src={backgroundFooter_mob} className={"jss_12"} alt='Footer' />
      )} */}
    </div>
  );
};
