import React from "react";
// import { Footer } from "../../components";
import { Banner } from "../../components/Banner";
import Enter_now from "../../assets/img/Enter_now_home_1.png";
import backgroundHome2 from "../../assets/img/bg_2_web_arb.png";
import backgroundHome2_Eng from "../../assets/img/bg_2_web_eng.png";
import backgroundHome_mob from "../../assets/img/bg_1_web_arb.png";
import backgroundHome_mob_Eng from "../../assets/img/bg_1_web_eng.png";
import Enter_now_eng from "../../assets/img/Enter_now_home_1_eng.png";
// import backgroundHome_eng from "../../assets/img/Background-home-2_1_eng.png";
// import backgroundHome_mob_eng from "../../assets/img/bannerBackground_mob_eng.png";
import Enter_Now_Button_English from '../../assets/img/Enter_Now_Button_English.png';
import Enter_Now_Button_Arabic from '../../assets/img/Enter_Now_Button_Arabic.png';
import { Collapse, IconButton, IconButtonProps, Button } from "@material-ui/core";
import { DoubleArrow } from "@material-ui/icons";

import { SubmitForm } from "../SubmitForm";
import styled from "@emotion/styled";

interface IProps {
  language: Language;
  page: Page;
  setPage: any;
  country: Country;
  setCountry: any;
}
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
}));

export const Home = ({ language, page, setPage, country, setCountry }: IProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const [screenWidth, setScreenWidth] = React.useState<number>(window.innerWidth);
  window.addEventListener("resize", (e: any) => setScreenWidth(e.target.innerWidth));

  const handleCountryFlagClick = () => {
    setExpanded(!expanded);

    setCountry("KSA");
    setPage("Form");
  };
  return (
    <div className='Page' style={{ marginBottom: expanded ? '0' : '0vh' }}>
      {screenWidth > 700 ? (
        <>
          <Banner handleButtonClick={handleCountryFlagClick} language={language} />
          <div className='jss_9' style={{ marginTop: '-1%' }}>
            <img
              src={language === 'English' ? backgroundHome2_Eng : backgroundHome2}
              className={screenWidth > 900 ? "jss_27" : "jss_27"}
              alt='Background_Home'
            />
            <div className={expanded ? `hidden` : ``} style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: 'transparent', position: 'absolute', top: '85%' }}>
              <img
                onClick={handleCountryFlagClick}
                src={language === "English" ? Enter_Now_Button_English : Enter_Now_Button_Arabic}
                style={{}}
                className=' jss_7 flag'
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <img
            src={language === 'English' ? backgroundHome_mob_Eng : backgroundHome_mob}
            className={"jss_27"}
            alt='Background_Home'
          />
          <img
            src={language === 'English' ? backgroundHome2_Eng : backgroundHome2}
            className={"jss_27"}
            alt='Background_Home'
            style={{}}

          />
          <div className={expanded ? `hidden` : ``} style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: 'transparent' }}>
            <img
              onClick={handleCountryFlagClick}
              src={language === "English" ? Enter_Now_Button_English : Enter_Now_Button_Arabic}
              style={{}}
              className=' jss_7 flag'
            />
          </div>
        </>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit style={{ marginTop: '2rem' }}>
        <SubmitForm
          page={page}
          setPage={setPage}
          country={country}
          setCountry={setCountry}
          language={language}
        />
      </Collapse>
    </div>
  );
};
